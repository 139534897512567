<template>
    <div class="payment-list" :class="adminMode ? '' : 'public'">

        <div class="d-flex flex-wrap align-items-start position-relative justify-content-between">
            <div class="clarifications text-left">
                <h3>{{ i18nT(`Payments`)}}</h3>
                <p>{{ i18nT(`Payments recorded for this invoice`)}}</p>
            </div>

            <div id="current-balance">
                <h4 >{{ i18nT(`Current Balance`)}}:</h4>
                <div class="d-inline-block text-right w-100 font-weight-bold" :class="allPaid ? 'text-success' : 'text-danger'">
                    <span v-if="allPaid">{{i18nT(`Paid in full`)}}</span>
                    <span v-else>- {{ {amount: dueAmount, currency: currency, locale: curLocale} | currency}}</span>
                </div>
            </div>
        </div>

        <div class="table text-left">
            <b-table
                v-if="paymentEntries.length > 0"
                ref="refDocumentListTable"
                :items="paymentEntries"
                :fields="paymentColumns"
            >

                <template #cell(CreatedAt)="data">
                    <span>{{ data.item.CreatedAt | date }}</span>
                </template>

                <template #cell(Amount)="data">
                    <span>{{ {amount: data.item.Amount, currency: currency, locale: curLocale} | currency }}</span>
                </template>

                <template #cell(PaymentType)="data">
                    <font-awesome-icon
                        :icon="['fas', paymentTypeIcon(data.item.PaymentType)]"
                        class="mr-1"
                    />
                    <span v-if="PaymentTypeDict && PaymentTypeDict[data.item.PaymentType]"
                    >{{ PaymentTypeDict[data.item.PaymentType] }}</span>
                </template>

                <template #cell(Description)="data">
                    <span>{{ data.item.Description | truncate(20)}}</span>
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item
                            v-if="data.item.PaymentChannel == 'manual'"
                            @click="onEditRecord(data.item)">
                            <feather-icon icon="EditIcon"/>
                            <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="onDeleteRecord(data.item)">
                            <feather-icon class="text-danger" icon="TrashIcon"/>
                            <span class="align-middle ml-50 text-danger" >{{ i18nT(`Delete`) }}</span>
                        </b-dropdown-item>

                    </b-dropdown>
                </template>

            </b-table>

            <div class="pt-2 border-top" v-else>
                <p class="text-center"><em>{{ i18nT(`No payments recorded for this invoice yet`)}}</em></p>
            </div>


        </div>

    </div>

</template>

<script>
import {
    BTable,
    BDropdown,
    BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    props: {
        paymentEntries: {
            type: Array,
            required: true,
        },
        dueAmount: {
            type: Number,
            required: true,
        },
        currency: {
            type: String,
            required: true,
        },
        curLocale: {
            type: String,
            required: true,
        },
        adminMode: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
    components: {
        BTable,
        BDropdown,
        BDropdownItem,

    },
    data() {
        return {
            PaymentTypeDict: [],
            paymentColumns : [
                { key: 'CreatedAt', label: this.i18nT(`Date`), sortable: true },
                { key: 'Amount', label: this.i18nT(`Amount`), sortable: true },
                { key: 'PaymentType', label: this.i18nT(`Via`), sortable: true }, //
                { key: 'Description', label: this.i18nT(`Description`), sortable: true },
                //{ key: 'Label', label: this.i18nT(`Label`), sortable: true },
                //{ key: 'PaymentChannel', label: this.i18nT(`Recorded by`), sortable: true }, //

            ],

            selectedCulture: '',
            cultures: [],
        }
    },
    methods: {
        paymentTypeIcon(type) {
            switch (type) {
                case 'cash':
                    return 'money-bill-alt'
                case 'sepa_debit':
                    return 'piggy-bank'
                case 'card':
                    return 'credit-card'
                case 'bank':
                    return 'university'
                default:
                    return 'money-bill-alt'
            }
        },
        openAddPaynmentModal() {
            this.$emit('openAddPaynmentModal')
        },
        onEditRecord(record) {
            this.$emit('editRecord', record)
        },
        onDeleteRecord(record) {
            let self = this;
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes delete it`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    self.$http.delete('invoices/invoicePayment?id=' + record.Id)
                        .then(({data}) => {
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            self.$emit('refresh')
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })

        },
    },
    async mounted() {
        if(this.adminMode){
            this.paymentColumns.push({ key: 'Actions', label: this.i18nT(`Actions`), sortable: false })
        }
        await this.$http.get(`system/invoicePaymentTypes`).then((resp) => {
            this.PaymentTypeDict = resp.data.data;
        });
    },
    computed: {
        allPaid() {
            return this.dueAmount > 0 ? false : true
        },
    }
}
</script>

<style scoped lang="scss">

.payment-list {
    min-width: 640px;
    margin-bottom: 1rem;
}

.payment-list.public {
    min-width: 800px;
}

@media screen and (max-width: 768px) {
    .payment-list {
        min-width: 100%;
    }
}

</style>
