<template>
    <div>
        <b-row>
            <b-col cols="12" class="mb-2">
                <div class="bg-white
                text-center
                d-flex
                justify-content-center
                align-items-center
                p-2
                box-shadow-1
                ">
                    <b-link
                        v-if="companyData"
                        :to="companyData.PublicUrl"
                        class="comp-logo-link d-flex align-items-center"
                        target="_blank"
                    >
                        <b-img
                            v-if="companyData.LogoUrl"
                            :src="companyData.LogoUrl"
                            class="comp-logo pl-1"
                        />
                        <h2 class="pt-1">{{ companyData.Label }}</h2>
                    </b-link>

                    <div class="sk-invoice-controls mx-2">
                        <b-button
                            v-if="false"
                            variant="outline-primary"
                            class="ml-2"
                            @click="printInvoice"
                        >
                            <font-awesome-icon
                                icon="print"
                                class="mr-1"
                            />
                            {{i18nT(`Print`)}}
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="downloadInvoice"
                            class="ml-2"
                        >
                            <font-awesome-icon
                                icon="download"
                                class="mr-1"
                            />
                            {{i18nT(`Download`)}}
                        </b-button>

                        <b-button
                            variant="primary"
                            @click="selectPaymentMethod"
                            class="ml-2"
                            v-if="(parseFloat(invoiceData.DueAmount) > 0 || invoiceData.IsPaid == 0) && paymentProviders.length > 0"
                        >
                            <font-awesome-icon
                                icon="credit-card"
                                class="mr-1"
                            />
                            {{i18nT(`Pay`)}}
                        </b-button>

                        <b-button
                            v-else-if="paymentProviders.length > 0"
                            variant="outline-primary"
                            :disabled="true"

                            class="ml-2">
                            {{i18nT(`Already paid`)}}
                        </b-button>
                    </div>

                    <div style="width: 120px">
                        <b-form-select
                            v-model="locale"
                            :options="cultures"
                            label="text"
                        />
                    </div>

                </div>
            </b-col>

            <b-col cols="12" md="8" offset-md="2"
                   class="mb-2"
                   v-if="invoiceData && invoiceData.IframePublicUrl">
                <iframe id="print-frame" :src="invoiceData.IframePublicUrl+`&lang=`+locale"
                        frameborder="0"></iframe>
            </b-col>
            <b-col cols="12" md="12">
                <div class="
                    bg-white
                    text-center
                    d-flex
                    justify-content-center
                    align-items-center
                    p-2
                    box-shadow-1
                ">
                    <invoice-payment-history
                        :payment-entries="invoiceData.InvoicePayments ? invoiceData.InvoicePayments : []"
                        :currency="invoiceData.Currency"
                        :due-amount="invoiceData.DueAmount"
                        :cur-locale="locale"
                        :adminMode="false"
                    />
                </div>
            </b-col>
        </b-row>

        <b-modal
            id="method-select"
            :title="i18nT(`Select payment method`)"
            :ok-title="i18nT(`Next`)"
            no-close-on-backdrop
            @ok="payInvoice"
        >
            <transition
                name="fade"
                tag="div"
                >
                <div class="sk-spinner-overlay" v-if="loading">
                    <div class="sk-spinner-and-text w-50 text-center">
                        <b-spinner
                            :variant="`primary`"
                            class="spinner-overlay__spinner"
                        />
                        <span class="mt-2 d-block">{{i18nT(`Please wait...`)}}</span>
                    </div>
                </div>
            </transition>


            <template #modal-footer>
                <div class="w-100 text-right">
                    <b-button
                        variant="secondary"
                        size="md"
                        class=""
                        @click="$bvModal.hide('method-select')"
                    >
                        {{i18nT(`Cancel`)}}
                    </b-button>

                    <b-button
                        variant="outline-primary"
                        size="md"
                        class="ml-1"
                        :disabled="paymentStep == 1"
                        @click="stepBack"
                    >
                        {{i18nT(`Back`)}}
                    </b-button>

                    <b-button
                        variant="primary"
                        size="md"
                        class="ml-1"
                        @click="payInvoice"
                    >
                        {{i18nT(`Next`)}}
                    </b-button>
                </div>
            </template>


            <b-row>
                <b-col cols="12">

                    <transition-group
                    type="transition"
                    name="fade"
                    @after-enter="onStepChanged"
                >

                    <div class=""
                         v-if="paymentStep == 1"
                         key="step1"
                    >

                        <!-- Due amount -->
                        <div class="picker-row border-bottom pb-2">
                            <h4 class="mb-1">{{i18nT(`Due amount`)}}</h4>
                            <b-list-group>

                                <b-list-group-item
                                    class="rounded-top pt-2 pb-1 d-flex justify-content-between"
                                    tag="li"
                                >
                                    <b-form-radio
                                        v-model="amountMode"
                                        value="full"
                                    >
                                        <div class="d-flex align-items-center">
                                            {{i18nT(`Unpaid balance`)}}
                                        </div>
                                    </b-form-radio>

                                    <h4 class="text-primary">{{invoiceData.Currency}} {{invoiceData.DueAmount}}</h4>

                                </b-list-group-item>

                                <b-list-group-item
                                    class="rounded-bottom d-flex justify-content-between align-items-center"
                                    tag="li"
                                >
                                    <b-form-radio
                                        v-model="amountMode"
                                        value="partial"
                                    >
                                        <div class="d-flex align-items-center">
                                            {{i18nT(`Partial payment`)}}
                                        </div>
                                    </b-form-radio>

                                    <div class="w-50 d-flex align-items-center">
                                        <span class="mr-1">{{invoiceData.Currency}}</span>
                                        <b-form-input
                                            id="Amount"
                                            type="number"
                                            v-model="methodDetails.Amount"
                                            :max="invoiceData.DueAmount"
                                            :min="1"
                                            :placeholder="'0.00 ' + invoiceData.Currency"
                                            class="ml-auto"
                                            :disabled="amountMode != 'partial'"
                                            required></b-form-input>
                                    </div>

                                </b-list-group-item>

                            </b-list-group>

                        </div>

                        <!-- Payment modality / method -->
                        <div class="picker-row mt-2">
                            <h4 class="mb-1">{{i18nT(`Payment method`)}}</h4>
                            <b-list-group>

                                <b-list-group-item
                                    class="rounded-top"
                                    tag="li"
                                >
                                    <b-form-radio
                                        v-model="paymentType"
                                        value="card"
                                    >
                                        <div class="d-flex align-items-center">
                                            <font-awesome-icon
                                                icon="credit-card"
                                                class="mr-1"></font-awesome-icon>
                                            {{i18nT(`Credit Card`)}}
                                        </div>
                                    </b-form-radio>
                                </b-list-group-item>

                                <b-list-group-item
                                    class="rounded-bottom"
                                    tag="li"
                                >
                                    <b-form-radio
                                        v-model="paymentType"
                                        value="bank"
                                    >
                                        <div class="d-flex align-items-center">
                                            <font-awesome-icon
                                                icon="university"
                                                class="mr-1"></font-awesome-icon>
                                            {{i18nT(`Bank transfer`)}}
                                        </div>
                                    </b-form-radio>
                                </b-list-group-item>

                            </b-list-group>

                        </div>

                    </div>

                    <!-- Step 2 -->
                    <div class="sk-step-mingheight w-100"
                         key="step2"
                         v-if="paymentStep == 2">

                        <h4 class="mb-1 mt-1">{{i18nT(`Payer details`)}}</h4>
                        <span class="mb-2">{{i18nT(`Please enter your details below. If you have an account with Stripe, please use the same e-mail.`)}}</span>
                        <div class="w-100 pt-2">
                            <validation-observer ref="clientData">
                                <div>
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="i18nT(`First and last name`)"
                                        rules="required"
                                        class="required"
                                    >
                                        <label class="text-muted">{{i18nT(`First and last name`)}}</label>
                                        <b-form-input type="text"
                                                      v-model="methodDetails.Name"
                                                      placeholder="Name"
                                                      required
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                                <div class="pt-1">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="i18nT(`Email`)"
                                        rules="required|email"
                                        class="required"
                                    >
                                        <label class="text-muted">{{i18nT(`Email address`)}}</label>
                                        <b-form-input type="text"
                                                      v-model="methodDetails.Email"
                                                      placeholder="Email"
                                                      required
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                            </validation-observer>

                        </div>
                    </div>


                    <!-- Step 3A - Card -->
                    <div
                        v-if="paymentStep == 3 && paymentType == 'card'"
                        class="sk-step-mingheight"
                        key="step3A"
                    >
                        <h4 class="mb-1 mt-1">{{i18nT(`Your credit card details`)}}</h4>
                        <span class="mb-2">{{i18nT(`You will be prompted to authorize a one-time payment from the specified card`)}}</span>

                        <div id="card-mount"
                                 class="mt-2 border rounded p-2"
                                 ref="card-mount"></div>

                    </div>


                    <!-- Step 3B - Bank -->
                    <div
                        v-if="paymentStep == 3 && paymentType == 'bank'"
                        key="step3B"
                    >
                        <h4 class="mb-1 mt-1">{{i18nT(`Your bank account`)}}</h4>
                        <span class="mb-2">{{i18nT(`You will be prompted to authorize a one-time direct debit from the specified account`)}}</span>
                        <div id="bank-mount"
                             class="mt-2 border rounded p-2"
                             ref="bank-account"></div>

                        <b-form-input type="text"
                                      id="accountTitle"
                                      v-model="methodDetails.bankAccount"
                                      placeholder="DE53CZ1234567890123456789"
                                      class="mb-1"
                                      required
                                      v-if="false"
                        />
                    </div>

                </transition-group>

                </b-col>
            </b-row>


            <b-row class="mt-2 mb-1">
                <transition-group
                    type="transition"
                    name="fade">
                <b-col cols="12"
                    key="stripeconnect"
                       v-if="paymentType == 'card' || paymentType == 'bank'"
                >
                    <div class="sk-provider-disclaimer">
                        {{i18nT(`Secure checkout powered by`)}}
                        <font-awesome-icon
                            v-if="providerStripe"
                            :icon="['fab', providerStripe.Icon]"
                            variant="primary"
                            class="sk-provider-icon"
                            size="3x"></font-awesome-icon>
                    </div>
                </b-col>

                <b-col cols="12"
                       key="paypal"
                       v-if="paymentType == 'account'"
                >
                    <div class="sk-provider-disclaimer">
                        {{i18nT(`Secure checkout powered by`)}}
                        <font-awesome-icon
                            :icon="['fab', providerPaypal.Icon]"
                            variant="primary"
                            class="sk-provider-icon"
                            size="3x"></font-awesome-icon>
                    </div>
                </b-col>
                </transition-group>
            </b-row>

        </b-modal>

    </div>
</template>

<script>
import {
    BButton,
    BRow,
    BCol,
    // BInputGroup,
    // BInputGroupText,
    //BFormGroup,
    BFormRadio,
    BFormInput,
    BFormSelect,
    // BCard,
    // BCardHeader,
    // BCardBody,
    // BCardText,
    BModal,
    BListGroup,
    BListGroupItem,
    //BFormSelectOption,
    // BForm,
    // BFormFile,
    // BFormTextarea,
    BImg,
    // BTabs,
    // BTab,
    BLink,
    // BInputGroupAppend,
    // BFormCheckbox
    //BCarousel,
    //BCarouselSlide,
    BSpinner
} from 'bootstrap-vue'

// import {dictToSelectArray} from '@core/utils/utils'
import store from "@/store";
import {
    StripePlugin,
    //StripeElementCard
} from '@vue-stripe/vue-stripe';
import InvoicePaymentHistory from "@/views/apps/invoice/InvoicePaymentHistory.vue";

import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {dictToSelectArray} from "@core/utils/utils";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email} from '@validations'

export default {
    components: {
        BButton,
        BRow,
        BCol,
        BModal,
        //BFormGroup,
        BFormSelect,
        BFormRadio,
        BFormInput,
        BListGroup,
        BListGroupItem,
        //BFormSelectOption,
        BLink,
        BImg,
        BSpinner,
        ValidationProvider,
        ValidationObserver,
        InvoicePaymentHistory,
        //BCarousel,
        //BCarouselSlide,
        //StripeElementCard
    },
    data() {
        return {
            //Validations
            required,
            email,

            locale: 'en',
            cultures: [],
            selectedPaymentMethod: null,
            methodDetails: {
                Amount: 0,
            },
            loading: false,
            amountMode: 'full',
            paymentType: 'card',

            paymentStep: 1,
            payment_id: null,

            //STRIPE DATA
            stripe_pk: null,
            stripe_customer_id: null,
            stripe_account_id: null,

            paymentIntent: null,

            //STRIPE ELEMENTS
            cardElement: null,
            bankElement: null,
        }
    },
    computed: {
        providerStripe(){
            return this.paymentProviders.find((item) => {
                return item.Short == 'stripeconnect';
            })
        },

        providerPaypal(){
            return this.paymentProviders.find((item) => {
                return item.Short == 'paypal';
            })
        },

        curInvoiceId(){
            return this.$route.params.id;
        },
        invoiceData(){
            return store.getters['invoices/getInvoiceData'];
        },
        paymentProviders(){
          return store.getters['invoices/getPaymentProviders'];
        },
        paymentMethods(){
            if(this.paymentProviders){
                return this.paymentProviders.map((item) => {

                    return {
                        value: item.Id,
                        text: item.Label
                    }
                })
            } else {
                return [];
            }
        },
        companyData(){
            if(this.invoiceData){
                return    this.invoiceData.WorkspaceObj;
            } else {
                return {};
            }
        },
    },
    created() {
        let params = {
            id: this.$route.params.id,
            hash: this.$route.params.hash
        }
        store.dispatch('invoices/fetchPublicInvoiceDetails', params);

        this.$http.get(`system/cultures`).then(({data}) => {
            this.cultures = dictToSelectArray(data.data)
        })

        if (this.$route.query.lang) {
            this.locale = this.$route.query.lang
        }
    },
    methods: {
        showError(message){
            if(!message) { message = this.i18nT(`An error has occurred. Please try again later.`) }
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    message: message
                },
                // onClose: () => {
                //     this.loading = false;
                // }
            });
            this.loading = false;
        },

        stepBack() {
            this.paymentStep--;
        },
        onStepChanged(){

            if(this.stripe_pk && this.paymentStep == 3 ){
                //Load Vue Stripe plugin with new pk
                let stripeOpts = {
                    pk: this.stripe_pk,
                    testMode: true, // Boolean. To override the insecure host warning
                    stripeAccount: this.stripe_account_id,
                    // apiVersion: '2020-08-27',
                    locale: this.locale
                };

                Vue.use(StripePlugin, stripeOpts);
            }

            if( this.paymentStep==3 && this.stripe_pk) {

                if(this.paymentType=='card' && !this.cardElement ) {

                    this.cardElement = this.$stripe.elements({locale: this.locale}).create('card');
                    this.cardElement.mount('#card-mount');

                }else if( this.paymentType=='bank' && !this.bankElement){

                    let bankOpts = {
                        supportedCountries: ['SEPA'],
                        placeholderCountry: 'FR',
                    };

                    this.bankElement = this.$stripe.elements({locale: this.locale}).create('iban', bankOpts);
                    this.bankElement.mount('#bank-mount');
                }


            } else {

                console.log("Mounted card element: ", this.cardElement);

            }

        },

        payInvoice(bvModalEvent){
            let formData = new FormData();
            bvModalEvent.preventDefault();

            let payload = {
                type: 'card',
                card: {
                    number: null,
                    exp_month: null,
                    exp_year: null,
                    last4: null,
                }
            }

            let self = this;

            switch (this.paymentStep){

                //STEP 1 - Enter payer name and Email -> Get payment OBJECT from server
                case 1:
                    this.paymentStep++;
                    break;

                case 2:
                    this.$refs.clientData.validate().then(success => {
                        if (success) {
                            formData.append('invoice_id', this.curInvoiceId);
                            formData.append('payer_name', this.methodDetails.Name);
                            formData.append('payer_email', this.methodDetails.Email);
                            formData.append('hash', this.invoiceData.Hash);
                            if(this.methodDetails.Amount){
                                formData.append('amount', this.methodDetails.Amount);
                            }
                            this.loading = true;
                            this.$http.post('stripeconnect/publicPreparePayment', formData).then((response) => {
                                console.log("Payment: ", response.data);

                                this.stripe_pk = response.data.data.stripe_pk;
                                this.stripe_customer_id = response.data.data.stripe_customer_id;
                                this.stripe_account_id = response.data.data.payment.ConnectedAccount;
                                this.payment_id = response.data.data.payment_id;

                                //Show card fields
                                this.paymentStep = 3;
                                this.loading = false;

                            }).catch((error) => {
                                this.showError(error.response.data.message);
                                console.log("publicPreparePayment Error: ", error);
                            });

                        }   else {
                            this.showError(this.i18nT(`Please fill in all required fields.`));
                        }
                    });


                    break;

                case 3:
                    //this.$refs.cardElementRef.submit();

                    //CREDIT CARD PAYMENT
                    if(this.paymentType == 'card') {
                        if(this.cardElement){
                            payload.card = this.cardElement;
                        }
                        this.loading = true;
                        this.$stripe.createPaymentMethod(payload).then((resp) => {
                            let method = resp.paymentMethod;
                            console.log("Payment method: ", method);

                            self.$stripe.createToken(this.cardElement).then((tokenResp) => {
                                console.log("Token: ", tokenResp);
                                self.token = tokenResp.token;

                                if(method.id && self.token.id){
                                    let formData = new FormData();
                                    formData.append('invoice_id', self.curInvoiceId);
                                    formData.append('stripe_customer_id', self.stripe_customer_id);
                                    formData.append('payment_id', self.payment_id);
                                    formData.append('payment_method_type', 'card');

                                    formData.append('stripe_token', self.token.id);
                                    formData.append('stripe_payment_method', method.id);
                                    this.$http.post('stripeconnect/publicPaymentIntent', formData).then((response) => {

                                        console.log("INTENT: ", response.data.data.payment_intent);
                                        let paymentIntent = response.data.data.payment_intent;


                                        self.$stripe.confirmCardPayment(paymentIntent.client_secret, {
                                            payment_method: method.id
                                        }).then((result) => {
                                            self.paymentStep = 3;
                                            console.log("Result: ", result);

                                            if(result.error){
                                                self.showError(result.error.message);
                                                console.log("STRIPE confirmCardPayment Error: ", result.error.message);

                                            }else if(result.paymentIntent.status === 'succeeded') {
                                                let localFormData = new FormData();

                                                localFormData.append('stripe_customer_id', self.stripe_customer_id);
                                                localFormData.append('payment_id', self.payment_id);
                                                localFormData.append('payment_intent_id', result.paymentIntent.id);

                                                self.$http.post(`stripeconnect/publicPayCard`, localFormData).then((finalResp) => {
                                                    console.log("Final response: ", finalResp.data);
                                                    self.$toast({
                                                        component: ToastificationContent,
                                                        position: 'top-right',
                                                        props: {
                                                            title: this.i18nT(`Your payment was successfully registered!`),
                                                            icon: 'AlertTriangleIcon',
                                                            variant: 'success'
                                                        }
                                                    })
                                                    self.loading = false;
                                                    this.$bvModal.hide('method-select');
                                                }).catch((error) => {
                                                    this.showError(error.message);
                                                    console.log("Error: ", error);

                                                });
                                            }
                                        }).catch((error) => {
                                            this.showError(error.message);
                                            console.log("confirmCardPayment Error: ", error.message);
                                        });

                                    }).catch((error) => {
                                        this.showError(error.message);
                                        console.log("publicPaymentIntent Error: ", error.message);
                                    });
                                }

                            }).catch((err) => {
                                this.showError(err.message);
                                console.log("Stripe create token Error: ", err.message);
                            });

                        }).catch((err) => {
                            this.showError(err.message);
                            console.log("Stripe create payment Error: ", err.message);
                        });


                    } else if (this.paymentType == 'bank') {
                        //BANK PAYMENT
                        let formData = new FormData();

                        formData.append('invoice_id', self.curInvoiceId);
                        formData.append('stripe_customer_id', self.stripe_customer_id);
                        formData.append('payment_id', self.payment_id);
                        formData.append('payment_method_type', 'sepa_debit');

                        self.loading = true;
                        this.$http.post('stripeconnect/publicPaymentIntent', formData).then((response) => {

                            console.log("INTENT: ", response.data.data);
                            self.paymentIntent = response.data.data;

                            if(self.paymentIntent.client_secret)
                            this.$stripe.confirmSepaDebitPayment(self.paymentIntent.client_secret, {
                                payment_method: {
                                    sepa_debit: self.bankElement,
                                    billing_details: {
                                        name: self.methodDetails.Name,
                                        email: self.methodDetails.Email
                                    }
                                },
                            }).then((result) => {
                                console.log("SEPA DEBIT RESULT: ", result);

                                if(result.error) {
                                    self.showError(result.error.message);
                                    console.log("STRIPE confirm SEPA DEBIT Error: ", result.error.message);
                                }else {
                                    let finalData = new FormData();
                                    finalData.append('payment_intent_id', result.paymentIntent.id);
                                    finalData.append('stripe_customer_id', self.stripe_customer_id);
                                    finalData.append('payment_id', self.payment_id);
                                    finalData.append('payer_name', self.methodDetails.Name);
                                    finalData.append('payer_email', self.methodDetails.Email);

                                    self.$http.post('stripeconnect/publicPayDirectDebit', finalData).then((finalResp) => {
                                        console.log("Final response: ", finalResp.data);
                                        self.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: this.i18nT(`Your payment was successfully registered!`),
                                                icon: 'AlertTriangleIcon',
                                                variant: 'success'
                                            }
                                        })
                                        self.loading = false;
                                        this.$bvModal.hide('method-select');

                                    }).catch((error) => {
                                        this.showError(error.message)
                                        console.log("publicPayDirectDebit Error: ", error.message);
                                    });

                                }

                            }).catch((error) => {
                                this.showError(error.message)
                                console.log("STRIPE confirmSepaDebitPayment error: ", error.message);
                            });

                        });

                    }

                    break;

            }

        },

        printInvoice(){
            let iframe = document.getElementById('print-frame');
            iframe.contentWindow.print();
        },

        downloadInvoice() {
            window.open(this.invoiceData.InvoiceDownloadUrl+'&lang='+this.locale, '_blank');
        },

        selectPaymentMethod(){
            this.$bvModal.show('method-select')
            this.methodDetails.Amount = this.invoiceData.DueAmount;
            //this.$router.push({name: 'payment-methods', params: {id: this.curInvoiceId}});
        },

    },
    watch: {
        locale (val) {
            this.changeLang(val)
            history.pushState({}, null, `${this.$route.path}?lang=${val}`)
        },
        paymentProviders(){
            if(this.paymentProviders){
                this.selectedPaymentMethod = this.paymentProviders[0].Id;
            }
        },
        curInvoiceId (val, oldVal) {
            if(val && val !== oldVal)
                store.dispatch('invoices/fetchPublicInvoiceDetails', val);
        }

    }
}
</script>

<style lang="scss" scoped>

#print-frame {
    width: 100%;
    height: calc(100vh - 400px);
    max-height: 1000px;
    min-height: 680px;
    border: none;
}

.sk-spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

div#stripe-card-row {
    padding-left: 1rem;
    padding-right: 1rem;
}


#stripe-card-row > div {
    width: 100%;
}

#stripe-element-form {
    width: 100%;
    height: 100%;
}

.custom-radio .custom-control-input {
    margin-top: 20px;
}

.sk-step-mingheight {
    min-height: 220px;
}

.sk-provider-icon {
    color: var(--primary);
    margin-bottom: -11px;
    margin-left: 4px;
}

.sk-provider-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sk-one-provider {
    width: 110px;
    height: 120px;
    border-radius: 5px;
}

.sk-selected {
    box-shadow-color: var(--primary);
}

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.card .card-header {
    justify-content: left;
    border-bottom: 5px solid var(--primary);
    margin-bottom: 10px;
}

.card .card-header h4 {
    margin-bottom: 0 !important;
}

.comp-logo {
    max-height: 60px;
    margin-right: 20px;
}

.comp-links {
    margin-left: auto;
}

.comp-links svg {
    margin-left: 10px;
}
</style>
